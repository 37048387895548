<template>
    <div>
        <v-app-bar
            fixed
            color="primary">
            <v-img
                class="mx-1"
                src="@/assets/logo.png"
                max-width="30"
                max-height="30"
                contain>
            </v-img> 
            <v-toolbar-title class="white--text ml-2">
                QuickWApp
            </v-toolbar-title>

           
            <v-spacer/>

            <v-btn
                class="primary--text"
                rounded
                small
                color="white"
                dark
                @click="shareQuickWApp">SHARE
                <v-icon 
                    right
                    color="primary">mdi-share</v-icon>
            </v-btn>

        </v-app-bar>
    </div>

</template>


<script>
import gtagAnalytics from '@/plugins/gtagAnalytics.js' 

export default {
    methods: {
        shareQuickWApp() {
            gtagAnalytics.sendEvent(this.$gtag, 'ShareBtnClick')
            window.open('whatsapp://send?phone=' + '&text=QuickWApp - Send messages to any numbers without saving them in your Contacts https://quickwapp.com', '_blank')
        },
    }
}

</script>