<template>
    <div v-show="showAd.name.length > 0">
        <span class="caption">Ad</span>
         <v-card
            class="mx-auto pa-2"
            color="grey darken-4"
            max-width="500">

            <div class="mt-2 white--text">
                <v-row
                class="px-2"
                no-gutters
                align="center"
                justify="space-between">

                <span>
                    <v-avatar size="40">
                    <img
                        :alt="showAd.name"
                        :src="showAd.icon"
                    >
                    </v-avatar>
                    <span class="ml-3 mt-3 font-weight-medium white--text">
                    {{ showAd.name }}
                    </span>
                </span>
                
                <span>
                    <v-btn
                        color="success"
                        @click="openLink">GET
                    </v-btn>
                </span>
                </v-row>
            </div>

            <div>
               <p class="mx-3 mt-3 body-2 white--text">{{ showAd.shortDescription }}</p>
            </div>

            <div class="mb-1 mx-2" v-for="(offer, i) in showAd.offers" :key="i">
                <span>
                    <v-icon color="primary">mdi-check-circle-outline</v-icon>
                    <span class="ml-1 mt-3 font-weight-medium subtitle-2 primary--text">
                    {{ offer }}
                    </span>
                </span>
            </div>



         </v-card>
    </div>
</template>


<script>
import gtagAnalytics from '@/plugins/gtagAnalytics.js' 

export default {
    data(){
        return {
            ads: [],
            showAd: {
                id: '',
                icon: '',
                name: '',
                shortDescription: ``,
                offers: [],
                link: ''
            }
            
        }
    },
    methods: {
        openLink() {
            gtagAnalytics.sendEvent(this.$gtag, 'OPEN AD - ' + this.showAd.name)
            window.open(this.showAd.link, '_blank') 
        },
        loadAds() {
            try {
                fetch('https://quickwapp.com/ads/ads.json')
                            .then(response => response.json())
                            .then(data => {
                                this.ads = data.Ads
                                this.setShowAd()
                            })
                            .catch(() => {
                                
                            })

            } catch(e) {
               console.log('FAILED LOAD ADS')
            }

        },
        setShowAd() {
            let adIndex = Math.floor(Math.random()*this.ads.length)
            let selectedAd = this.ads[adIndex]

            this.showAd.name = selectedAd.name
            this.showAd.id = selectedAd.id
            this.showAd.icon = selectedAd.icon
            this.showAd.shortDescription = selectedAd.shortDescription
            this.showAd.link = selectedAd.link
            this.showAd.offers = selectedAd.offers.slice()

            gtagAnalytics.sendEvent(this.$gtag, 'SHOW AD - ' + this.showAd.name)
        }
    },
    created() {
        this.loadAds()
    }
}
</script>
